import React from 'react';
import { Form, Input } from 'antd';
import * as qs from 'qs';
import { withTranslation } from 'react-i18next';
import Card from 'common/components/Card';
import Loader from 'common/components/Loader';
import useAuthorization from 'common/hooks/useAuthorization';
import useUpdateStaffPassword from 'common/hooks/useUpdateStaffPassword';
import { validateToNextPassword } from 'common/utils';
import useAssets from 'common/hooks/useAssets';
import useFaviconUpdate from 'common/hooks/useFaviconUpdate';
import actions from 'common/actions';
import { ASYNC_STATES, DEFAULT_MERCHANT_ID } from 'common/constants';
import {
  MainWrapper,
  Description,
  StyledFormItemInput,
  SubmitButton,
  LoginTitle,
  ThemeLogo,
  ThankYouContainer,
  LogoWrapper,
} from './elements';

function Main({
  t,
  location,
  match: {
    params: { organisationId },
  },
  history: { push },
}) {
  const { token, redirect, merchantId } = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (!token) {
    push('/not-found');
  }
  const [form] = Form.useForm();
  useFaviconUpdate(organisationId);
  useAuthorization();
  const [assetsState] = useAssets(organisationId, redirect);
  const [updatePasswordState, dispatchUpdatePasswordState] = useUpdateStaffPassword();

  const handleSubmit = values => {
    actions.updateStaffPassword({
      dispatch: dispatchUpdatePasswordState,
      token,
      values,
      merchantId,
      redirect,
      organisationId,
      setFields: form.setFields,
    });
  };

  const localeKey = organisationId || DEFAULT_MERCHANT_ID;

  const renderThankYouContainer = () => <ThankYouContainer>Password changed.</ThankYouContainer>;
  const renderFormContainer = () => (
    <div>
      <LogoWrapper>
        <ThemeLogo src={assetsState.logoImage} />
      </LogoWrapper>
      <LoginTitle align="left">{t('common:title')}</LoginTitle>
      <Description>{t(`common:description.${localeKey}`)}</Description>
      <Form form={form} scrollToFirstError onFinish={handleSubmit} layout="vertical">
        <StyledFormItemInput
          name="password"
          label={t('common:input_1.label')}
          rules={[
            {
              required: true,
              message: t('common:input_1.requiredError'),
            },
            {
              validator: validateToNextPassword(t(`common:input_1.validationError.${localeKey}`), localeKey),
            },
          ]}
        >
          <Input.Password placeholder={t('common:input_1.placeholder')} size="large" />
        </StyledFormItemInput>
        <StyledFormItemInput
          name="confirm-password"
          rules={[
            {
              required: true,
              message: t('common:input_2.requiredError'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('common:input_2.validationError'));
              },
            }),
          ]}
          label={t('common:input_2.label')}
          dependencies={['password']}
        >
          <Input.Password placeholder={t('common:input_2.placeholder')} size="large" />
        </StyledFormItemInput>
        <SubmitButton htmlType="submit" disabled={updatePasswordState.asyncState === ASYNC_STATES.LOADING}>
          {t('common:save')}
        </SubmitButton>
      </Form>
    </div>
  );

  const renderContent = () => {
    if (updatePasswordState.asyncState === ASYNC_STATES.SUCCESS) {
      return renderThankYouContainer();
    }
    return renderFormContainer();
  };

  return (
    <MainWrapper organisationId={organisationId}>
      <Card>{assetsState.asyncState === ASYNC_STATES.LOADING ? <Loader /> : renderContent()}</Card>
    </MainWrapper>
  );
}

export default withTranslation(['common'])(Main);
