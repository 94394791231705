import gbIcon from 'assets/images/gb.svg';
import jpIcon from 'assets/images/jp.svg';
import arIcon from 'assets/images/ar.svg';
import esIcon from 'assets/images/es.svg';
import { APP_LOCALES } from 'common/services/i18n';
import getEnvVar from '../utils/getEnvVar';

export const ENDPOINTS = {
  LEGACY_DODDLE_API_URL: `${getEnvVar('REACT_APP_LEGACY_DODDLE_API_URL')}` || 'DEFAULT_VALUE_LEGACY_DODDLE_API_URL',
  ASSETS_URL: `${getEnvVar('REACT_APP_ASSETS_URL')}` || 'DEFAULT_VALUE_ASSETS_URL',
};

export const KEYS = {
  API_KEY: `${getEnvVar('REACT_APP_DODDLE_API_KEY')}` || 'DEFAULT_VALUE_DODDLE_API_KEY',
  API_SECRET: `${getEnvVar('REACT_APP_DODDLE_API_SECRET')}` || 'DEFAULT_VALUE_DODDLE_API_SECRET',
};

export const ASYNC_STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const PRODUCT = 'PASSWORD_RESET';
export const TOKEN_KEY = 'passwordresetpage/token';
export const TOKEN_LIFETIME = 'passwordresetpage/tokenlifetime';
export const DEFAULT_MERCHANT_ID = 'DODDLE'; // it's value used in public/icons !

export const languages = [
  { title: 'English', value: APP_LOCALES.EN, iconName: gbIcon },
  { title: 'Japanese', value: APP_LOCALES.JA, iconName: jpIcon },
  { title: 'Arabic', value: APP_LOCALES.AR, iconName: arIcon },
  { title: 'Spanish', value: APP_LOCALES.ES, iconName: esIcon },
];

export const footerLinks = {
  termsAndConditions: {
    STARLINKS: 'https://connect-starlinks.com/docs/CONNECT_Terms_and_Conditions.pdf',
  },
  privacyPolicy: {
    STARLINKS: 'https://connect-starlinks.com/docs/CONNECT_Privacy_Policy.pdf',
  },
};

export const availableLanguages = {
  STARLINKS: [APP_LOCALES.EN, APP_LOCALES.AR],
  GLS_ES: [APP_LOCALES.ES],
  DEFAULT: [APP_LOCALES.EN],
};
